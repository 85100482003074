
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { getPostList, getPostTotalCount } from '@/api/post';
import Pagination from '@/components/Pagination/index.vue';
import { isActive } from '@tiptap/core';

@Component({
  components: {
    Pagination,
  },
})

export default class extends Vue {
  mounted() {
    this.getPostList(0);
  }

  private listQuery = {
    boardUid: '74f59e08-cf5e-42ed-ae3e-781ba429d88c',
    page: 0,
    size: 10,
    inquiryStatus: 0, // 0 전체 1 답변대기 2 답변완료
    searchType: '',
    searchValue: '',
    sort: 'createDate',
  }

  private totalElements = 0;

  private postList = [];

  private totalCount = 0;

  private readyCount = 0;

  private completeCount = 0;

  private loading = true;

  private getPostList(status: number) {
    this.listQuery.inquiryStatus = status;
    this.loading = true;
    getPostList(this.listQuery).then((res) => {
      this.postList = res.data.content;
      console.log(this.postList);
      this.totalElements = res.data.totalElements;
      this.loading = false;
    });

    getPostTotalCount({ ...this.listQuery, inquiryStatus: 0 }).then((res) => {
      this.totalCount = res.data.totalCount;
      this.readyCount = res.data.readyCount;
      this.completeCount = res.data.completeCount;
    });
  }

  private isNewPost(date: string) {
    let isNew = false;
    const postDate = moment(date);
    if (moment().diff(postDate, 'days') < 7) isNew = true;
    return isNew;
  }

  private goBack() {
    this.$router.go(-1);
  }

  private isCategory(uid: string) {
    switch (uid) {
      case '6b5789ad-325e-4cd8-857c-c76dd4956a81':
        return 'category';
      case '7761ed23-73fb-4049-ac40-1f36c5e7e1c2':
        return 'cancel';
      case '990e754f-7e95-4877-b36d-e77aedb37efd':
        return 'reserve';
      default:
        return 'category';
    }
  }
}
